:root {
  font-family: "Pretendard Variable", "Apple SD Gothic Neo", "나눔바른고딕", Noto Sans KR, sans-serif;
  word-break: keep-all;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

:root:lang(ja),
:root:lang(zh),
:root:lang(zh-CN) {
  word-break: break-all;
}

:root::before,
:root:lang(ja)::before,
:root:lang(zh)::before,
:root:lang(zh-CN)::before {
  word-break: keep-all;
}
