*,
*::before,
*::after {
  box-sizing: border-box;
  transition: none 0.2s ease-in-out;
}

html {
  white-space: nowrap;
  overflow-x: hidden;
  top: 0;
  right: 0;
  left: 0;
  font-size: 16px;
  color: rgb(251, 249, 249);
  background-color: rgb(8, 8, 13);
}

/*
 * 모바일에서 주소창 숨김 등으로 높이가 바뀔 때에 그에 맞추어 높이를 조절하는 역할
 *
 * 2019년 9월 19일 기준으로 CRA 기본 설정으로는 CSS Polyfill 지원이 없어서 직접 vendor-prefix도 작성한 것이므로,
 * 지원이 생기면 min-height: available;과 height: auto;만 있어도 충분함
 */
html {
  min-height: 100%;
  min-height: -webkit-fill-available;
  min-height: -moz-available;
  min-height: available;
  height: auto;
}

html.disableScroll {
  position: fixed;
}

html,
input,
button {
  line-height: 1.4;
}

html,
input,
textarea,
pre {
  font-family: "Noto Sans KR", sans-serif;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
input,
button,
ol,
ul,
li,
figure,
p,
pre {
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  margin: 0 auto;
}

ol,
ul {
  list-style: none;
}

img {
  vertical-align: top;
}

a {
  cursor: pointer;
  display: inline-block;
  color: inherit;
  text-decoration: none;
}

a:disabled {
  pointer-events: none;
}

input,
button {
  border: 0;
  border-radius: 0;
  outline: 0;
  background-color: transparent;
  font-size: inherit;
}

input::placeholder {
  color: hsl(0 0% 64%);
}

input,
textarea {
  resize: none;
  outline: none;
  vertical-align: middle;
  line-height: 1.4;
  border: 0;
  color: inherit;
  font-size: inherit;
}

strong {
  display: inline-block;
}

button {
  cursor: pointer;
  text-transform: inherit;
  color: inherit;
}

html:lang(ko) {
  font-family: "Pretendard Variable", "Apple SD Gothic Neo", "나눔바른고딕", Noto Sans KR, Noto Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif;
}

body,
html {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  position: relative;
}
